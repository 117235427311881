
.company-contain {
    height: 100%;
    background-color: #ffffff;

    .head-box {
        display: flex;
        height: 40px;
        padding: 10px 30px 0 20px;
        position: relative;

        .title {
            margin-top: 10px;
        }
    }

    .table-box {
        padding-top: 20px;
        padding-bottom: 30px;

        .introForm {
            .el-input {
                width: 410px;
            }

            .upload-box {
                display: flex;
                flex-wrap: wrap;

                .upload-main {
                    width: 100px;
                    height: 100px;
                    cursor: pointer;
                    background: #EFF0FF;
                    border-radius: 2px;
                    color: #4D5AFF;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 16px;
                    }
                }

                .showImg-box {
                    margin-left: 20px;
                    margin-bottom: 20px;

                    &:nth-child(8n) {
                        margin-left: 0px;
                    }

                    &:nth-child(1) {
                        margin-left: 0px;
                    }

                    .logo-box {
                        width: 100px;
                        height: 100px;
                        border: 1px solid #EEEEEE;
                        border-radius: 2px;
                    }

                    .qa-box {
                        max-width: 154px;
                        max-height: 212px;
                    }
                }

                .img-box {
                    margin-left: 40px;
                    position: relative;

                    &:nth-child(3) {
                        margin-left: 20px;
                    }

                    .logo-box {
                        width: 100px;
                        height: 100px;
                        border: 1px solid #EEEEEE;
                        border-radius: 2px;
                    }

                    .qa-box {
                        max-width: 154px;
                        max-height: 212px;
                    }

                    .el-icon-error {
                        width: 14px;
                        height: 14px;
                        color: #4D5AFF;
                        cursor: pointer;
                        position: absolute;
                        right: -20px;
                        top: 0;
                    }
                }
            }

            .intro-box {
                width: 400px;
                white-space: normal;
                word-break: break-all;
                word-wrap: break-word;
            }
        }

        .footer-box {
            justify-content: center;
            display: flex;

            button {
                width: 80px;
                height: 32px;
                line-height: 4px;
                border-radius: 2px;
            }

            .left-btn {
                background: #4D5AFF;
            }
        }
    }

    .form-contain {
        .head-box {
            &:after {
                content: '';
                position: absolute;
                height: 2px;
                background: #E5E5E5;
                bottom: -5px;
                left: 10px;
                right: 10px;
            }
        }
    }
}
